.img-login {
    width: 15%;
    }
    .card-login {
    border-radius: 5%;
    width: 65%;
    margin-left: 20%;
    }
    /* .cardLOGIN{
       
            margin-top: 24%!important;;
            position: relative;
            height: 300px;
            border: 1px solid #ccc;
            border-radius: 3%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        
    } */
    .card1 {
        position: relative;
        /* height: 200px; */
        border: 1px solid #ccc;
        border-radius: 3%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: rgb(255 255 255);
    }

.logo-quess{
    width: 50%;
    padding: 12%;
        }
.div-form{
    height: 100%!important;
    margin-left: 8%!important;
    margin-bottom: 5%!important;
    margin-top: -10%!important;
    width: 85%!important;
        } 
.label-login{
    color: #4F4F4F;
    opacity: 1;
    font-size: 12px;
}
.login-form{
    padding-top: 15%;
}
.btn-login{
    --bs-btn-border-radius: 3.375rem;
    background-color: #01689C;
    border-color: #01689C;
    width:100%;
    margin-top: 7%;
} 
.input-login{
    background-color:#F1F3F2;
    opacity:1;
}
.row{
    justify-content: center;
}
.form-group{
    text-align: -webkit-left!important;
} 

.contactUs{
    margin-top:5%!important;
    margin-left:30%!important;
    margin-right: 30%!important;
}
.custom-button {
    font-size: 18px; /* Taille du texte */
  }
  
.custom-icon {
    font-size: 24px; /* Taille de l'icône */
  }
@media screen and (min-width: 770px) and (max-width: 1024px) {
    .card-login {
        border-radius: 5%!important;
        width: 100%!important;
        margin-left: 3%!important;
    }
    .div-form {
        height: 100%!important;
        margin-left: 7%!important;
        margin-bottom: 5%!important;
        margin-top: -10%!important;
        width: 85%!important;
    }
    
}
@media screen and (min-width: 1024px)  {
   
    .div-form {
        height: 100%!important;
        margin-left: 7%!important;
        margin-bottom: 5%!important;
        margin-top: -10%!important;
        width: 85%!important;
    }
    .contactUs {
        margin-top: 5%!important;
        margin-left: 40%!important;
        margin-right: 30%!important;
    }
}


@media screen and (min-width: 280px)  and (max-width: 769px) {
    .card-login {
        border-radius: 5%;
        width: 96%;
        margin-left: 2%;
    }
    .div-form {
        height: 100%;
        margin-bottom: 5%;
        margin-top: -10%;
        max-width: 100%;
        margin-left: 0%;
    }
}

