.logo {
    width: 50%;
    margin-top: 17%;
    align-self: center!important;
    }
.div-text{
    height: 126px;
    width: 150px;
    color: #808080;
    opacity: 1;
    font-size: 12px;
    text-align: center;
    line-height: 2rem;
    font-family: sans-serif;
    text-justify: inter-word;
}   
.button-style1{
    width:50%;
    --bs-btn-border-radius: 3.375rem;
    margin-top: -15%;
    background-color: #01689C;
    border-color: #01689C;
    font-size: 16px;
    font-weight: 500;
} 
.button-style2{
    width:50%;
    --bs-btn-border-radius: 3.375rem;
    margin-top: -15%;
    background-color: #5D5D5D;
    border-color: #5D5D5D;
    font-size: 16px;
    font-weight: 500;
} 
.button-style3{
    width:50%;
    --bs-btn-border-radius: 3.375rem;
    margin-top: -15%;
    background-color: #019C82;
    border-color: #019C82;
    font-size: 16px;
    font-weight: 500;
} 
.button-style1:hover{
    width:50%;
    --bs-btn-border-radius: 3.375rem;
    margin-top: -15%; 
    color: var(--bs-btn-hover-color);
    background-color: #01346D;
    border-color: #01346D;
    
} 
.button-style2:hover{
    width:50%;
    --bs-btn-border-radius: 3.375rem;
    margin-top: -15%; 
    color: var(--bs-btn-hover-color);
    background-color: #282828;
    border-color: #282828;
    
} 

.button-style3:hover{
    width:50%;
    --bs-btn-border-radius: 3.375rem;
    margin-top: -15%;
    background-color: #037562;
    border-color: #037562;
    
} 
.card-style{
    width:95%;
    height:20rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color:white;
}
.card-text{
font-size: 150%;
}
.img-logo{
    width: 27%;
    margin-bottom: 10px;
}
.div-card{
    text-align: -webkit-center;
    margin-bottom: 17%;

}


.row{
    min-width: 100%!important;
}




@media screen and (min-width: 760px) and (max-width: 1368px) {  
.button-style1{
    width:50%;
    --bs-btn-border-radius: 3.375rem;
    margin-top: 8%;
    background-color: #01689C;
    border-color: #01689C;
    font-size: 16px;
    font-weight: 500;
} 
.button-style2{
    width:50%;
    --bs-btn-border-radius: 3.375rem;
    margin-top: 8%;
    background-color: #5D5D5D;
    border-color: #5D5D5D;
    font-size: 16px;
    font-weight: 500;
} 
.button-style3{
    width:50%;
    --bs-btn-border-radius: 3.375rem;
    margin-top: 8%;
    background-color: #019C82;
    border-color: #019C82;
    font-size: 16px;
    font-weight: 500;
} 
.button-style1:hover{
    width:50%;
    --bs-btn-border-radius: 3.375rem;
    margin-top: 8%; 
    color: var(--bs-btn-hover-color);
    background-color: #01346D;
    border-color: #01346D;
    
} 
.button-style2:hover{
    width:50%;
    --bs-btn-border-radius: 3.375rem;
    margin-top: 8%; 
    color: var(--bs-btn-hover-color);
    background-color: #282828;
    border-color: #282828;
    
} 

.button-style3:hover{
    width:50%;
    --bs-btn-border-radius: 3.375rem;
    margin-top: 8%;
    background-color: #037562;
    border-color: #037562;
    
} 
}
@media screen and (min-width: 539px) and (max-width: 759px) { 
    .logo {
        width: 50%;
        margin-top: 9%;
        align-self: center!important;
    }
}
@media screen and (max-width: 992px) { 

.div-card {
    text-align: -webkit-center;
    margin-bottom: 17%;
    width: 50%;
}
.button-style1 {
    width: 50%;
    --bs-btn-border-radius: 3.375rem;
    margin-top: -3%;
    background-color: #01689C;
    border-color: #01689C;
    font-size: 16px;
    font-weight: 500;
}
}
@media screen and (max-width: 440px) { 
    .para {
        font-family: sans-serif!important;
        text-align: left!important;
        letter-spacing: 0px!important;
        color: #FFFFFF!important;
        opacity: 1!important;
        float: left!important;
        text-align: center!important;
        margin-top: 2%!important;
        font-size: 160%!important;

    }
    .div-card {
        text-align: -webkit-center;
        margin-bottom: 17%;
        width: 100%;
    }
}
.titleQuess{
    color:#01689C!important;
    font-family: sans-serif!important;
}




@media screen and (min-width: 280px) and (max-width: 700px) {  
    .button-style1{
        width:50%;
        --bs-btn-border-radius: 3.375rem;
        margin-top: -10%;
        background-color: #01689C;
        border-color: #01689C;
        font-size: 16px;
        font-weight: 500;
    } 
    .button-style2{
        width:50%;
        --bs-btn-border-radius: 3.375rem;
        margin-top: -10%;
        background-color: #5D5D5D;
        border-color: #5D5D5D;
        font-size: 16px;
        font-weight: 500;
    } 
    .button-style3{
        width:50%;
        --bs-btn-border-radius: 3.375rem;
        margin-top: -10%;
        background-color: #019C82;
        border-color: #019C82;
        font-size: 16px;
        font-weight: 500;
    } 
    .button-style1:hover{
        width:50%;
        --bs-btn-border-radius: 3.375rem;
        margin-top: -10%; 
        color: var(--bs-btn-hover-color);
        background-color: #01346D;
        border-color: #01346D;
        
    } 
    .button-style2:hover{
        width:50%;
        --bs-btn-border-radius: 3.375rem;
        margin-top: -10%; 
        color: var(--bs-btn-hover-color);
        background-color: #282828;
        border-color: #282828;
        
    } 
    
    .button-style3:hover{
        width:50%;
        --bs-btn-border-radius: 3.375rem;
        margin-top: -10%;
        background-color: #037562;
        border-color: #037562;
        
    } 
    .card-text{
        font-size: 180%;
        }
    }