.card {
    position: relative;
    height: 200px; /* ou la hauteur que vous préférez */
    border: 1px solid #ccc;
    border-radius: 3%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}



.card-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: background-color 0.3s, opacity 0.3s;
}

.card:hover .card-hover {
    background-color: #00407C;
    opacity: 0.9;
}


.card-description {
    color: white;
    text-align: center;
    padding: 20px;
}

.card:hover .card-description {
    visibility: visible;
}
.logo {
    margin-top: 35%;
    width: 60%;
    align-self: center!important;
}

.circle-image {
    text-align: center;
    align-self: center!important;
    position: relative!important;
    margin-top: -20%!important;
}


@media screen and (min-width: 1701px) and  (max-width: 2500px) {
   
    .card {
        margin-top: 24%!important;;
        position: relative;
        height: 300px;
        border: 1px solid #ccc;
        border-radius: 3%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .image-app{
        width: 60%!important;
    }
}
@media screen and (min-width: 1501px) and  (max-width: 1700px) {
   
    .card {
        margin-top: 24%!important;;
        position: relative;
        height: 260px;
        border: 1px solid #ccc;
        border-radius: 3%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .image-app{
        width: 60%!important;
    }
}
@media screen and (min-width: 1300px) and  (max-width: 1500px) {
   
    .card {
        margin-top: 24%!important;;
        position: relative;
        height: 250px;
        border: 1px solid #ccc;
        border-radius: 3%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .image-app{
        width: 60%!important;
    }
    .circle-image {
        text-align: center;
        align-self: center!important;
        position: relative!important;
        margin-top: -27%!important;
    }
}
@media screen and (min-width: 900px) and  (max-width: 1100px) {
   
    .card {
        margin-top: 24%!important;;
        position: relative;
        height: 165px;
        border: 1px solid #ccc;
        border-radius: 3%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .image-app{
        width: 60%!important;
    }
    .circle-image {
        text-align: center;
        align-self: center!important;
        position: relative!important;
        margin-top: -27%!important;
    }
}
@media screen and (min-width: 768px) and  (max-width: 898px) {
   
    .card {
        margin-top: 24%!important;;
        position: relative;
        height: 260px;
        border: 1px solid #ccc;
        border-radius: 3%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .image-app{
        width: 60%!important;
    }
}
@media screen and (min-width: 700px) and  (max-width: 767px) {
   
    .card {
        margin-top: 24%!important;;
        position: relative;
        height: 260px;
        border: 1px solid #ccc;
        border-radius: 3%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .image-app{
        width: 60%!important;
    }
}
@media screen and (min-width: 601px) and  (max-width: 699px) {
   
    .card {
        margin-top: 24%!important;;
        position: relative;
        height: 220px;
        border: 1px solid #ccc;
        border-radius: 3%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .image-app{
        width: 60%!important;
    }
}
@media screen and (min-width: 768px)  and (max-width: 992px) {
    /* .circle-image {
        text-align: center;
        align-self: center!important;
        position: relative!important;
        margin-top: -33%!important;
    } */

}
@media screen and (min-width: 576px)  and (max-width: 767px) {
    /* .circle-image {
        text-align: center;
        align-self: center!important;
        position: relative!important;
        margin-top: -33%!important;
    } */
    .image-app{
        width: 60%!important;
    }
}
@media screen and (min-width: 555px) and  (max-width: 600px) {
   
    .card {
        margin-top: 24%!important;;
        position: relative;
        height: 360px;
        border: 1px solid #ccc;
        border-radius: 3%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .image-app{
        width: 60%!important;
    }
}
@media screen and (min-width: 360px) and  (max-width: 554px) {
   
    .card {
        margin-top: 24%!important;;
        position: relative;
        height: 300px;
        border: 1px solid #ccc;
        border-radius: 3%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .image-app{
        width: 60%!important;
    }
}