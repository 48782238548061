

.nav-nav{
    float:left;
    margin-left:430px;
}
.nav-text{
    color: #494949;
    opacity: 1;
    font-size: 15px;
    line-height: 1rem;
    font-family: sans-serif;
    text-justify: inter-word;
}
.profil-text{
    color: #494949;
    opacity: 1;
    font-size: 12px;
    line-height: 1rem;
    font-family: sans-serif;
    text-justify: inter-word;
    
}
.icon-nav{
    margin:5px;
}


/*******************************NEW CSS******************************/
.para {
    font-family: sans-serif;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 180%;
    position: relative;
    top: 20%;
    transform: translateY(-50%);
}

.nav-div {
    background-image: url('../../../public/images/bar.png')!important;
    width: 100%!important;
    height: 6rem!important; 
    margin-bottom: 0.2%!important;
    text-align: center; 
    display: flex;
    align-items: center;
    justify-content: center;
}
.divNav{
    width: 100%!important;
    margin-left: 6%;

}
.nav-nav {
    float: left!important;
}
.nav-button {
    float: left!important;
    width: 100%!important;
    padding-top: 4%!important;
    padding-bottom: 4%;
    margin-top: 43%;
}
.dropdown-menu {
    left: -63px!important;
    margin-top: var(--bs-dropdown-spacer)!important;
}




.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}


.navbar-nav:nth-child(1) {
    flex: 1; /* Occupe l'espace restant à gauche */
    text-align: right;
}

.divNav1 {
    width: 50%;
    margin-left: -30%;
}

/* Pour le conteneur à gauche avec le dropdown */
.divNav2 {
    flex: 1!important; /* Occupe l'espace à droite */
    display: flex!important;
    justify-content: flex-end!important;
    flex-direction: column!important;
    float: right !important;
    padding-right: 9%;
}

.imgLogo{
    width: 60% !important;
    margin-right: 26% !important;
    height: 95%;
}
.contactNavUs{
    margin-top: 2%;
    width: 100%;
}









.username{
    white-space: break-spaces;
    word-wrap: break-word;
}
.grid-container {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
  }
  
 