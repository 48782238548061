  .form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  form {
    width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  .logoContact{
    width: 60%;
    padding: 11%;
  }
  input[type='text'],
  input[type='email'],
  textarea {
    width: 100%;
    padding: 8px;
    border-radius: 3px;
    border: 1px solid #ccc;
  }
  
  button[type='submit'] {
    padding: 10px 20px;
    color: #fff;
    background-color: #01689C;
    border-color: #01689C;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    --bs-btn-border-radius: 3.375rem;
    width:50%;
    margin-left: 24%;
  }
  
  button[type='submit']:hover {
    background-color: #01689C;
    border-color: #01689C;
    width:50%;
    margin-left: 24%;
  }
 


  @media screen and (min-width: 1023px) {
  form {
    width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    margin-top: 14%;
}
}
@media screen and (max-width: 376px) {
  .form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 40% 10% 10% 10%;

  }
}

@media screen and (min-width: 377px) and  (max-width: 520px){
  .form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 0% 10% 10% 10%;

  }
}
@media screen and (min-width: 521px) and  (max-width: 541px){
  .form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 40% 10% 10% 10%;

  }
}
@media screen and (min-width: 521px) and  (max-width: 541px){

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 25% 10% 10% 10%;
}
}