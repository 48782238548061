
@media screen and (min-width: 760px) and (max-width: 1368px) { 
  .main {
    background-image: url('../public/images/background2.jpg')!important;
    background-size: cover!important;
    opacity: 1;
    width: 100%;
    min-height: 86rem!important;
}
}
.main {
  background-image: url('../public/images/background2.jpg')!important;
  background-size: cover!important;
  opacity: 1;
  width: 100%;
  min-height: 67rem;
}

code {
font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}