.barPass{
    max-width: 20%!important;
  }
.img-forget-pass {
    width: 15%;
    }
    .card-forget-pass {
        height: 100%;
        border-radius: 5%;
        margin-left: 15%;
        width: 75%;
    }
.logo-quess-pass{
        width:50%;
        padding: 6%;
    
        }
.div-form-forget-pass{
    border-radius: 5%;
    height: 100%;
    margin-left: 8%;
    margin-bottom: 5%;
    margin-top: -2%;
    width: 85%!important;
        } 
.label-forget-pass{
    color: #4F4F4F;
    opacity: 1;
    font-size: 12px;
}

.btn-forget-pass{
    --bs-btn-border-radius: 3.375rem;
    background-color: #01689C;
    border-color: #01689C;
    width: 81%!important;
    margin-left: 10%!important;
    margin-top: 50px;
    margin-top: 7%;
} 
.input-forget-pass{
    background-color:#F1F3F2;
    opacity:1;
}

.forgetform{
    padding-top: 1%!important;
}





