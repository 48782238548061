.wrapper {
    width: 520px;
    text-align: left;
    margin: 55px auto;
  }
  .wrapper {
    margin-top: 50%!important;
    width: 490%;
    margin: 0 auto;
  }
  .pwd-checker-bar {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 10px;
  }
  .pwd-checker-bar::-webkit-progress-bar {
    background-color: rgb(246, 241, 241);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
  }
  .pwd-label {
    font-size: 22px;
  }
  .pwd-checker-bar::-webkit-progress-value {
    border-radius: 4px;
    background-size: 30px 18px, 100% 100%, 100% 100%;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
  }
  .label {
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .strength-weak::-webkit-progress-value {
    background-color: #e20b07;
  }
  .strength-fair::-webkit-progress-value {
    background-color: #ebbd04;
  }
  .strength-good::-webkit-progress-value {
    background-color: #0b75ed;
  }
  .strength-strong::-webkit-progress-value {
    background-color: #01a917;
  }
  .weak span {
    color: #e20b07;
  }
  .strength-fair span {
    color: #ebbd04;
  }
  .strength-good span {
    color: #0b75ed;
  }
  .strength-strong span {
    color: #01a917;
  }

 .headerStyle  {
  background-color:#0093cb;
 color:#FFFFFF;
}

.borders {
  border: solid 1px #0093cb;
  width: 100%;
}

.cellPadding {
  padding-left:10px;
}