.banner-user {
    background-image: url('../../../public/images/bar.png');
    height : 135px;
    margin-bottom: 5%;
    margin-top: 4%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 6px;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: 7px;
    /* opacity: 0.7; */
    margin-right: auto;
    margin-left: auto;
    background-size:cover;
    width: 97%!important;
  }
  
  
  
  .banner-user-elem {
    text-align: center;
    color: hsl(0, 0%, 100%);
    position: relative;
    overflow: hidden;
  }
  
  .banner-user-elem .banner-user-title {

    display:flex;
    font-size: 35px;
    font-weight: bold;
    margin-right: auto;
    align-content: center;
    text-align: right; 
    margin-left: 1%;
    margin-top:-1%;    
  }
  
  .banner-user-elem .banner-user-text {
    color: hsl(0, 0%, 100%);
    display: flex;
    font-size: 16px;
    font-weight: bold;
    margin-right: auto;
    margin-left: 1%;
    text-align: right;
    white-space: pre-wrap;
  }
  
  .banner-anim-elem .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
  }
  .banner-anim-thumb-default span {
    width: 15px;
    height: 15px;
    border-radius: 8px;
    margin: 0 5px;
    background: #899aa8;
    transition: background .3s;
    box-shadow: 0 0 10px #00407C ;
    display: none!important;
  }
      
      
      @keyframes shine {
        0% {
          background-position: 0;
        }
        
        100% {
          background-position: 800px;
        }
      }

.BannerDiv{
  width: 85%!important;
  margin-left:6%!important;
  display: inline-block;
}
.news{
  display: inline-block;
}
@media screen and (min-width: 901px) and  (max-width: 1350px) {
  .banner-user {
    background-image: url('../../../public/images/bar.png');
    height : 160px;
    margin-bottom: 5%;
    margin-top: 4%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 6px;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: 7px;
    /* opacity: 0.7; */
    margin-right: auto;
    margin-left: auto;
    background-size:cover;
    width: 97%!important;
  }
}
@media screen and (min-width: 690px) and  (max-width: 900px) {
  .banner-user {
    background-image: url('../../../public/images/bar.png');
    height : 178px;
    margin-bottom: 5%;
    margin-top: 4%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 6px;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: 7px;
    /* opacity: 0.7; */
    margin-right: auto;
    margin-left: auto;
    background-size:cover;
    width: 97%!important;
  }
}
@media screen and (min-width: 600px) and  (max-width: 700px) {
  .banner-user {
    background-image: url('../../../public/images/bar.png');
    height : 200px;
    margin-bottom: 5%;
    margin-top: 4%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 6px;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: 7px;
    /* opacity: 0.7; */
    margin-right: auto;
    margin-left: auto;
    background-size:cover;
    width: 97%!important;
  }
}
@media screen and (min-width: 566px) and  (max-width: 600px) {
  .banner-user {
    background-image: url('../../../public/images/bar.png');
    height : 200px;
    margin-bottom: 5%;
    margin-top: 4%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 6px;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: 7px;
    /* opacity: 0.7; */
    margin-right: auto;
    margin-left: auto;
    background-size:cover;
    width: 97%!important;
  }
}
@media screen and (min-width: 400px) and  (max-width: 565px) {
  .banner-user {
    background-image: url('../../../public/images/bar.png');
    height : 250px;
    margin-bottom: 5%;
    margin-top: 4%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 6px;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: 7px;
    /* opacity: 0.7; */
    margin-right: auto;
    margin-left: auto;
    background-size:cover;
    width: 97%!important;
  }
}
@media screen and (min-width: 280px) and  (max-width: 399px) {
  .banner-user {
    background-image: url('../../../public/images/bar.png');
    height : 351px;
    margin-bottom: 5%;
    margin-top: 4%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 6px;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: 7px;
    /* opacity: 0.7; */
    margin-right: auto;
    margin-left: auto;
    background-size:cover;
    width: 97%!important;
  }
}