.barPass2fa{
    max-width: 20%!important;
  }
.img-forget-pass2fa {
    width: 15%;
    }
    .card-forget-pass2fa {
        height: 100%;
        border-radius: 5%;
        margin-left: 15%;
        width: 75%;
    }
    
.logo-quess-pass2fa{
        width: 45%;
        padding: 6%;
    
        }
        .card-verif2fa {
            padding: 4%;
            border-radius: 5%;
            width: 81%;
            margin-left: 9%;
            background-color: #f9f9f9;
            }
.div-form-forget-pass2fa{
    border-radius: 5%;
    height: 100%;
    margin-left: 8%;
    margin-bottom: 5%;
    margin-top: -15%;
    width: 85%!important;
        } 
.label-forget-pass2fa{
    color: #4F4F4F;
    opacity: 1;
    font-size: 90%;
}

.btn-forget-pass2fa{
    --bs-btn-border-radius: 3.375rem;
    background-color: #01689C;
    border-color: #01689C;
    width: 81%!important;
    margin-left: 10%!important;
    margin-top: 50px;
    margin-top: 7%;
} 
.input-forget-pass2fa{
    background-color:#F1F3F2;
    opacity:1;
}

.verifyForm{
    padding-top: 1%;
}

.textVerify{
    font-size: 100%;
}




